const siteKeys = {
  main: process.env.RECAPTCHA_SITE_KEY,
  emailJoin: process.env.RECAPTCHA_SITE_KEY_ICONTACT
};
window.recaptchaWidgetIds = {};

try {
  setRecaptcha();
} catch (error) {
  console.error(error);
}

async function setRecaptcha () {
  const grecaptcha = await loadRecaptcha();
  grecaptcha.ready(() => {
    if (document.getElementById('email_join_captcha')) {
      window.recaptchaWidgetIds.emailJoin = grecaptcha.render('email_join_captcha', {
        sitekey: siteKeys.emailJoin,
        size: 'invisible',
        callback: function () {
          document.getElementById('ic_signupform').submit();
        }
      });
    }

    if (document.getElementById('group_cruise_captcha')) {
      window.recaptchaWidgetIds.groupCruise = grecaptcha.render('group_cruise_captcha', {
        sitekey: siteKeys.main,
        theme: 'light'
      });
    }

    if (document.getElementById('cruise_review_captcha')) {
      window.recaptchaWidgetIds.cruiseReview = grecaptcha.render('cruise_review_captcha', {
        sitekey: siteKeys.main,
        theme: 'light'
      });
    }

    if (document.getElementById('tours_request_quote_captcha')) {
      window.recaptchaWidgetIds.requestTourQuote = grecaptcha.render('tours_request_quote_captcha', {
        sitekey: siteKeys.main,
        size: 'invisible',
        callback: function (response) {
          document.getElementById('request_quote').submit();
        }
      });
    }
  });
}

function loadRecaptcha () {
  return new Promise((resolve, reject) => {
    if (typeof grecaptcha !== 'undefined') {
      // eslint-disable-next-line no-undef
      resolve(grecaptcha); // reCAPTCHA is already loaded
    } else {
      const script = document.createElement('script');
      script.src = 'https://www.google.com/recaptcha/api.js?render=explicit';
      script.async = true;
      script.defer = true;
      script.onload = () => {
        // eslint-disable-next-line no-undef
        resolve(grecaptcha); // Resolve the promise when the script loads
      };
      script.onerror = () => {
        reject(new Error('Failed to load reCAPTCHA'));
      };
      document.head.appendChild(script);
    }
  });
}
